import React, {useLayoutEffect, useState} from 'react';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {Email, LocationOn, Phone} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import Logo from "../img/logo.png";

// google maps, adresa, contact
const mapOptions: google.maps.MapOptions =
    {
        streetViewControl: false,
        mapTypeControl: false,
    }

const Footer = () => {
    const center: google.maps.LatLngLiteral = {lat: 45.5505089, lng: 22.8017847};

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBu2cSGoc9p4DOQV8FwNlC7rBjyaqf4Wls",
    });

    return (
        <>
            <footer className="footer-area pt-85 pb-60"
                    style={{display: "flex", justifyContent: 'center', alignContent: 'center'}}>
                <div className={'row container'}>
                    <div className={'footer-container col-lg-6 col-sm-12'}>
                        {isLoaded &&
                        <div className={'google-maps-container'}>
                            <GoogleMap
                                mapContainerStyle={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                center={center}
                                zoom={15}
                                options={mapOptions}
                            >
                                {
                                    <Marker
                                        title={'CountrySide Life'}
                                        position={center}
                                        onClick={() => {
                                            window.location.href =
                                                'https://www.google.com/maps/place/Dc91+83,+Pe%C8%99teni%C8%9Ba+337209/@45.5505089,22.8017847,17z/data=!4m5!3m4!1s0x474e6ed6bfbd1111:0xc0e3b41d35ec8bd!8m2!3d45.551952!4d22.802745?hl=ro';
                                        }}
                                    />
                                }
                            </GoogleMap>
                        </div>
                        }
                    </div>
                    <div className={'col-lg-6 col-sm-12'} style={{ color: 'white', paddingTop: 20, fontSize: 16}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                            flexDirection: 'column'
                        }}>
                            {/*<h5 style={{color: "white"}}>CountrySide Life</h5>*/}
                            <img src={Logo} style={{height: 120, width: 120}}/>
                            <p><LocationOn className={'footer-icon'}/><a href={'https://www.google.com/maps/place/Dc91+83,+Pe%C8%99teni%C8%9Ba+337209/@45.5505089,22.8017847,17z/data=!4m5!3m4!1s0x474e6ed6bfbd1111:0xc0e3b41d35ec8bd!8m2!3d45.551952!4d22.802745?hl=ro'}>Dc91 83, Peștenița 337209, România</a></p>
                            <p><Phone className={'footer-icon'}/><a href={'tel:0723659484'}>0723 659 484</a></p>
                            <p><Phone className={'footer-icon'}/><a href={'tel:0254775180'}>0254 775 180</a></p>
                            <p><Email className={'footer-icon'}/><a href={'mailto:countrysidelifero@gmail.com'}>countrysidelifero@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copy-right-area" style={{backgroundColor: '#21262c'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="copyright-text">
                                <NavLink to={'/admin'}><p>Această activitate a fost realizată cu fonduri europene A.F.I.R S.M. 6.2</p></NavLink>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="copyright-text">
                                <a href={'https://dotkoda.ro'}><p>© Powered by dotKoda</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;